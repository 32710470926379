import $ from 'jquery';

/**
 * Homepage JS
 */
class Homepage {

	constructor (){

		this.videosrc = '/video/';//'https://www.youtube.com/embed/L_uRyG7T1Qg?rel=0&controls=0&showinfo=0&autoplay=1';

		$('.audience-side .audience-name').on('click', function() {
			if($(this).parent('.audience-side').hasClass('active')) {
				$('.audience-side').removeClass('active');
			}
			else {
				$('.audience-side').removeClass('active');
				$(this).parent('.audience-side').addClass('active');
			}
		});

		$('.video-layer .play-btn').on('click', () => {
			$('iframe.video').css('display','block');
			var iframe_content = document.getElementById('video').contentDocument || document.getElementById('video').contentWindow.document;
			iframe_content.getElementById('front-video').play();
			setTimeout( function() {
				$('iframe.video, .video-layer .close-btn, .close-btn').addClass('show');
			},200);
		});

		$('.video-layer .close-btn, .close-btn').on('click', () => {
			$('.video-layer iframe, .video-layer .close-btn, iframe.video, .close-btn').removeClass('show');
				document.getElementById('video').contentDocument.getElementById('front-video').pause();
			setTimeout( function() {
				$('.video-layer iframe, iframe.video').css('display','none');
				//$('.video-layer iframe, iframe.video').removeAttr('src');
			},200);
		});
		window.addEventListener('message', function(event) {
			if (event.isTrusted && event.data.message == 'video done') {
				setTimeout( function() {
					$('.video-layer iframe, .video-layer .close-btn, iframe.video, .close-btn').removeClass('show');
					$('.video-layer iframe, iframe.video').css('display','none');
					//$('.video-layer iframe, iframe.video').removeAttr('src');
				},200);
			}
		}, false);
	}

}

module.exports = Homepage;
