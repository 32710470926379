import "babel-polyfill";

/**
 * Checklists JS
 */
class Checklists {

	constructor (){

		this.checklists = Array.from(document.querySelectorAll('.checklist'));

		this.svg = '<svg version="1.1" class="scribble" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32.25px" height="32.25px" viewBox="3.875 4.25 32.25 32.25" enable-background="new 3.875 4.25 32.25 32.25" xml:space="preserve"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M20.362,13.294c1.053,1.417,2.131,3.166,0.174,4.475c-1.035,0.692-4.971,0.833-3.583-1.385c1.273-2.035,6.135-1.902,7.667-0.443 c2.992,2.85,0.674,8.578-2.707,9.93c-4.272,1.709-10.923-1.276-11.754-5.99c-0.989-5.606,3.614-9.834,8.979-9.21 c3.34,0.389,9.39,4.563,7.222,8.657c-1.886,3.556-8.754,2.791-11.311,0.492c-2.387-2.145-3.147-6.339-1.042-8.908 c4.351-5.311,12.904-1.96,17.25,1.48c5.188,4.106,4.606,11.945,0.593,16.728c-4.661,5.551-11.694,7.414-17.756,3.295 c-4.672-3.174-8.558-8.534-8.975-14.302c-0.466-6.437,7.848-12.306,13.65-12.704c6.103-0.419,11.1,4.607,11.778,10.341 c0.316,2.661,0.027,5.734-0.822,8.291c-0.905,2.725-3.121,4.178-5.643,5.23c-4.59,1.92-9.533,2.815-14.502,2.694 c-1.797-0.044-3.2-0.989-4.576-2.033" /></svg>';

		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){

		this.checklists.forEach((checklist) => {
			Array.from(checklist.querySelectorAll('ul li')).forEach((item) => {

				// Appends the svg scribble to the checklist item
				item.innerHTML += this.svg;

				// Generate a random rotation for each scribble
				let rotation = Math.random() * 180;
				item.querySelector('.scribble').style.transform = 'rotate(' + rotation + 'deg)';

				item.addEventListener('click', () => {
					if(item.classList.contains('active')) {
						item.classList.remove('active');
						item.querySelector('.scribble').style.display = 'none';
					}
					else {
						item.classList.add('active');
						item.querySelector('.scribble').style.display = 'block';
					}
				});
			});
		});
	}

}

module.exports = Checklists;
