import $ from 'jquery';

/**
 * Subsections JS
 */
class Subsections {

	constructor (){

		this.header = $('header');
		this.pageOverlay = $('#page-overlay');

		this.backToTop = $('.page-subsections .back-to-top');
		this.mobileExpander = $('.expand-indicator');
		this.sectionListItems = $('.page-subsections ul li');
		this.ListItemsCount = this.sectionListItems.length;

		this.sectionLinks = $('.page-subsections ul li a');
		this.sections = $('section.page-subsection');
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){

		let numOfItems = this.ListItemsCount - 1;
		this.sectionListItems.each((i,item) => {
			let delay = '0.' + numOfItems + 's';
			$(this.sectionListItems[i]).css('transition-delay',delay);
			numOfItems = numOfItems - 1;
		});

		this.mobileExpander.on('click',() => {
			if(this.mobileExpander.hasClass('open')) {
				this.closeSectionLinks();
			}
			else {
				this.header.removeClass('hide');
				this.sectionListItems.removeClass('responsive-hide');
				this.pageOverlay.addClass('show').fadeIn();
				setTimeout(() => {
					this.mobileExpander.addClass('open');
					this.sectionListItems.addClass('show');
				}, 200);
			}
		});

		this.sectionLinks.on('click',(e) => {

			e.preventDefault();
			let clicked = e.currentTarget;
			let anchor = $(clicked).attr('href').substring(1);
			let section = 'a[name=' + anchor + ']';
			this.closeSectionLinks();
			$('html, body').animate({
				scrollTop: $(section).offset().top - 180
			}, 800);

		});

		this.backToTop.on('click',() => {
			$('html, body').animate({
				scrollTop: $('body').offset().top
			}, 600);
		});

		$(window).on('scroll', () => {

			// console.log('scrolled' + $(window).scrollTop() + ' vs ' + $(window).height());

			if($(window).scrollTop() > $(window).height()/2) {
				$(this.backToTop).addClass('show');
			}
			else {
				$(this.backToTop).removeClass('show');
			}

			// Highlight nav items
			$('section.page-subsection .page-subsection-anchor').each(function(i, section){
			
				let anchorsectionBCR = section.getBoundingClientRect();


				if(anchorsectionBCR.top < 450) {
					let activeSection = $(section).attr('name');
					// console.log(anchorsectionBCR.bottom);

					$('.page-subsections ul li').removeClass('active');
					$('.page-subsections ul li').find('a[data-section="' + activeSection + '"]').parent('li').addClass('active');
				
				}

			});
		});
	}

	closeSectionLinks () {
		let timeoutdelay = this.ListItemsCount * 100;
		this.mobileExpander.removeClass('open');
		this.sectionListItems.removeClass('show');
		this.pageOverlay.removeClass('show').fadeOut();
		setTimeout(() => {
			this.sectionListItems.addClass('responsive-hide');
		}, timeoutdelay);
	}

}

module.exports = Subsections;
