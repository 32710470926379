import $ from 'jquery';

/**
 * Health Tip JS
 */
class HealthTip {

	constructor (){

		this.healthTipTip = $('.layout-health_tip .health-tip-bubble .health-tip');
		this.healthtipsButton = $('.layout-health_tip .health-tip-bubble .button');
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){

		// this.healthTipTip.hide();
		this.healthtipsButton.on('click',function(e){

			let healthTip = $(e.currentTarget).parent();
			let currentTip = healthTip.find('.health-tip');

			// console.log(currentTip);

			if ( healthTip.hasClass('open') ) {
				healthTip.removeClass('open');
				setTimeout(function(){
					currentTip.animate({ width: 'toggle' });
				},350);
			}
			else {
				currentTip.animate({ width: 'toggle' });
				setTimeout(function(){
					healthTip.addClass('open');
				},350);
			}
		});
	}

}

module.exports = HealthTip;
