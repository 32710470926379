import $ from 'jquery';

/**
 * Content Reveal JS
 */
class ContentReveal {

	constructor (){

		this.revealers = $('.layout-content_reveal .reveal');
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){

		this.revealers.on('click',function(e){

			$(this).next('.to-reveal').slideToggle();
			$(this).toggleClass('active');

		});
	}

}

module.exports = ContentReveal;
