import $ from 'jquery';

/**
 * Full-Width Accordion JS
 */
class FullWidthAccordion {

	constructor (){

		this.fwaccordiontabs = $('.layout-list_w_expandable .expand-tab');
		this.fwaccordioncontents = $('.expand-content');
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){

		this.fwaccordioncontents.slideUp();

		this.fwaccordiontabs.on('click',function(e){

			$(this).toggleClass('active');
			$(this).parent().toggleClass('active');
			$(this).next('.expand-content').slideToggle();

		});
	}

}

module.exports = FullWidthAccordion;
