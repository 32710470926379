import Header from './modules/header';
import NavBurger from './modules/navburger';
import Subsections from './modules/subsections';
import Homepage from './modules/homepage';
import GridAccordion from './modules/gridaccordion_jq';
import FullWidthAccordion from './modules/fullwidthaccordion';
import HealthTip from './modules/healthtip';
import ContentReveal from './modules/contentreveal';
import Checklists from './modules/checklists';


class App {

	constructor (){
		// just some console fun.
		console.log('%cBuilt by', 'font: 200 16px Calibri;color:#CCC');
		console.log('%cSignals', 'font: 200 28px Calibri;color:#93cb3c');
		console.log('%chttp://www.signals.ca', 'font: 200 16px Calibri;color:#CCC');

		this.behaviors();

	}

	/**
	** Load Classes based on body CSS class
	*/
	behaviors (){

		if(document.querySelector('body.front')){//.classList.contains('front')) {
			this.homepage = new Homepage();
		}
		else {
			this.header = new Header();
		}

		if(document.querySelector('.navburger') !== null) {
			this.navburger = new NavBurger();
		}

		if(document.querySelector('.layout-grid-accordion') !== null) {
			this.gridaccordion = new GridAccordion();
		}

		if(document.querySelector('.layout-list_w_expandable') !== null) {
			this.checklists = new FullWidthAccordion();
		}

		if(document.querySelector('.layout-health_tip') !== null) {
			this.healthtip = new HealthTip();
		}

		if(document.querySelector('.layout-content_reveal') !== null) {
			this.contentreveal = new ContentReveal();
		}

		if(document.querySelector('.page-subsections') !== null) {
			this.subsections = new Subsections();
		}

		if(document.querySelector('.checklist') !== null) {
			this.checklists = new Checklists();
		}

	}
}

window.App = new App();