import $ from 'jquery';

/**
 * NavBurger JS
 */
class NavBurger {

	constructor (){

		this.navburger = $('.navburger');
		this.navburgerBtn = $('.navburger .button');
		this.navburgerMenu = $('.navburger .navburger-menu');
		this.subsectionExpander = $('.expand-indicator');
		this.pageOverlay = $('#page-overlay');

		this.events();

	}

	/**
	 * Event listeners
	 */
	events (){

		this.navburgerMenu.hide();

		$(this.navburgerBtn).on('click', () => {
			this.navburgerMenu.slideToggle();
			this.navburger.toggleClass('open');
			this.pageOverlay.toggleClass('show').fadeToggle();
			this.subsectionExpander.toggleClass('hide');
		});

	}

}

module.exports = NavBurger;
