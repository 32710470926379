import $ from 'jquery';

/**
 * Header JS
 */
class Header {

	constructor (){

		this.header = $('header');
		this.subsectionMenu = $('ul.page-subsections');
		// this.floatingBtn = $('#floating-btn');

		this.navburger = $('.navburger');
		this.navburgerBtn = $('.navburger .button');
		this.navburgerMenu = $('.navburger .navburger-menu');

		this.events();

	}

	/**
	 * Event listeners
	 */
	events (){

		let position = $(document).scrollTop();

		$(document).on('scroll', (e) => {

			if( $(document).height() - 50 < $(window).scrollTop() + $(window).height() ) {
			    this.header.removeClass('hide');
			    this.subsectionMenu.removeClass('up');
			    // this.floatingBtn.removeClass('up');
			}
			else {
				let scroll = $(e.currentTarget).scrollTop();

				if(scroll > 200 && scroll > position) {
					// 260 = height of header * 2
					if($('#page-overlay').hasClass('show')) {
						// Don't hide header if overlay is visible
					}
					else {
						this.subsectionMenu.addClass('up');
						// this.floatingBtn.addClass('up');
						this.header.addClass('hide');
					}
				}
				else {
					this.header.removeClass('hide');
					this.subsectionMenu.removeClass('up');
					// this.floatingBtn.removeClass('up');
				}
				position = scroll;
			}

		});

	}

}

module.exports = Header;
