import $ from 'jquery';

/**
 * Grid Accordion JS
 */
class GridAccordion {

	constructor (){
		this.screenwidth = $(window).width();
		this.accordiontabs = $('.accordion-items .accordion-title');
		this.events();
	}
	

	/**
	 * Event listeners
	 */
	events (){
		$('.accordion-contents').hide();
		this.accordiontabs.on('click',(e) => {
			let currentTabContents = $(e.currentTarget).next('.accordion-contents');
			if (this.screenwidth >= 600) {
				$('.accordion-contents').slideUp('100');
			} else {
				
			}
			if(currentTabContents.is(':visible')){
				setTimeout(function(){
					$(e.currentTarget).removeClass('active');
				},300);
				if (this.screenwidth < 600) {
					currentTabContents.slideUp('100');
				}
			}
			else {
				if (this.screenwidth >= 600) {
					this.accordiontabs.removeClass('active');
				}
				$(e.currentTarget).addClass('active');
				setTimeout(function(){
					currentTabContents.slideDown('200');
				},200);
			}
		});
	}

}

module.exports = GridAccordion;
